import { Container, Box, Grid, Typography, FormControl, FormGroup, Checkbox, FormControlLabel, CircularProgress, IconButton } from "@mui/material";
import { Link } from 'react-router-dom';
import Input from '../../components/input'
import Button from "../../components/button";
import Spacer from "../../components/spacer";
import './login.css';
import { useEffect, useState } from "react";
import axios from "axios";
import AuthLayout from "../../layouts/auth";
import API from "../../utils/api";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Login = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isLoading, setIsLoading] = useState();
    const [showPassword, setShowPassword] = useState(false);

    const handleLogin = () => {

        setIsLoading(true);

        API.post('/login' , {
            email: email,
            password: password
        })
        .then(res => {

            setEmail('');
            setPassword('');
            setIsLoading(false);

            localStorage.setItem('tokenStorage', res.data.access_token);

            if(window.location.hostname === "localhost"){
                window.location.href = `http://localhost:3000/visao-geral`;
            }else{
                window.location.href = `https://bivitrinerevit.mtxgroup.co/visao-geral`;
            }

        })
        .catch(err => {

            setIsLoading(false);
            alert("Dados inválidos");
            
        })

    }

    return(
        <AuthLayout>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Typography className="title">Entre na sua <br /> conta!</Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography>E-mail</Typography>
                    <Input placeholder="Digite seu e-mail" fullWidth value={email} onChange={(e) => {setEmail(e.target.value)}} />
                </Grid>
                <Grid item md={12} xs={12}>
                    <Typography>Senha</Typography>
                    {/* <Input type="password" placeholder="Digite sua senha" fullWidth value={password} onChange={(e) => {setPassword(e.target.value)}} /> */}
                    <Input
                        type={!showPassword && 'password'}
                        placeholder="Digite sua senha"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={true}
                        endAdornmentIcon={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        handleClickEndAdornment={() => {setShowPassword(!showPassword)}}
                    />
                </Grid>
                <Grid item md={12} xs={12} className="footer">
                    <Box>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox />} label="Lembrar-me" />
                        </FormGroup>
                    </Box>
                    <Link to="esqueceu-senha" className="link">
                        <Typography 
                            className="forgot"
                            sx={{
                                '&:hover': {
                                    textDecoration: 'underline',
                                }
                            }}
                        >
                            Esqueceu sua senha?
                        </Typography>
                    </Link>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Button 
                        onClick={() => {
                            handleLogin();
                        }} 
                        disabled={!email || !password}
                        size="large" 
                        variant="contained" 
                        fullWidth 
                        label={isLoading ? <CircularProgress size={20} sx={{ color: '#FFF' }} /> : 'Entrar'}
                    />
                </Grid>
            </Grid>
        </AuthLayout>
    )
}

export default Login;